import {VIP_MANAGE} from "@/constant/VIP_MANAGE";
import {
  BUSINESS_QUARTER_INFO,
  BUSINESS_VIP_INFO, BUSINESS_VIP_MONTH_INFO,
  PERSON_QUARTER_INFO,
  PERSON_VIP_INFO,
  PERSON_VIP_LIFELONG
} from "@/constant/VIP";
import {
  COMPANY_All_PURPOSE,
  COMPANY_All_PURPOSE_HALF,
  COMPANY_ONLINE,
  COMPANY_ONLINE_HALF
} from "@/constant/VIP_COMPANY";
import { SERVICE_LINK } from "@/constant/_Public/Path.js"

export const PAY = {
  /* 支付页 banner介绍 */
  BANNER_INTRO: {
    TITLE: '一站式满足设计需求，提升工作效率的好帮手',
  },

  /* 购物车操作类型 */
  ACCOUNT_CART_HANDLE: {
    ADD: 'add',
    REDUCE: 'reduce'
  },

  /* 自定义套餐类型 */
  VIPS: [
    {
      ...VIP_MANAGE.PERSON.INFO,
      title: '个人VIP：限学习交流，不可商用',
      CLASS_NAME: "business",
      LIST: [
        {
          ...VIP_MANAGE.PERSON.YEAR,
          desc: '个人海量下载',
          tagClass: 'person-year',
          mandateNum: '个人VIP：限学习交流，不可商用（共5项授权）',
          scopePermission: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          equitySimple: [
            {
              isHas: 0,
              label: '个人授权使用'
            },
            {
              isHas: 0,
              label: '图片包一键下载'
            },
            {
              isHas: true,
              label: '个人海量下载'
            },

            {
              isHas: true,
              label: '图片版权保障'
            },
            {
              isHas: true,
              label: '在线申请发票'
            },
            {
              isHas: true,
              label: '专属客服1对1'
            },
          ],
          equityMore: [
            {
              isHas: 0,
              label: '新媒体用途'
            },
            {
              isHas: 0,
              label: '电商平台'
            },
            {
              isHas: 0,
              label: '线上推广'
            },
            {
              isHas: 0,
              label: '物料印刷'
            },
            {
              isHas: 0,
              label: '线下活动'
            },
            {
              isHas: 0,
              label: '户外广告'
            },
            {
              isHas: 0,
              label: '影视制作'
            },
            {
              isHas: 0,
              label: '传媒平台'
            },
            {
              isHas: 0,
              label: '实物印刷'
            },
            {
              isHas: 0,
              label: '商品生产'
            },
            {
              isHas: 0,
              label: '产品内置'
            },
            {
              isHas: 0,
              label: '敏感用途'
            },
            {
              isHas: 0,
              label: '商标LOGO'
            },
            {
              isHas: 0,
              label: '内置转售'
            }
          ],
          downScope: ['个人VIP专享', '限时免费']
        },
        {
          ...VIP_MANAGE.PERSON.QUARTER,
          desc: '个人下载 50张/天',
          mandateNum: '个人VIP：限学习交流，不可商用（共5项授权）',
          equitySimple: [
            {
              isHas: 0,
              label: '个人授权使用'
            },
            {
              isHas: 0,
              label: '图片包一键下载'
            },
            {
              isHas: true,
              label: '个人下载50张/天'
            },

            {
              isHas: true,
              label: '图片版权保障'
            },
            {
              isHas: true,
              label: '在线申请发票'
            },
            {
              isHas: true,
              label: '专属客服1对1'
            },
          ],
          scopePermission: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          downScope: ['个人VIP专享', '限时免费']
        },
        {
          ...VIP_MANAGE.PERSON.MONTH,
          desc: '个人下载 10张/天',
          mandateNum: '个人VIP：限学习交流，不可商用（共5项授权）',
          equitySimple: [
            {
              isHas: 0,
              label: '个人授权使用'
            },
            {
              isHas: 0,
              label: '图片包一键下载'
            },
            {
              isHas: true,
              label: '个人下载10张/天'
            },
            {
              isHas: true,
              label: '图片版权保障'
            },
            {
              isHas: true,
              label: '在线申请发票'
            },
            {
              isHas: true,
              label: '专属客服1对1'
            },
          ],
          scopePermission: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          downScope: ['个人VIP专享', '限时免费']
        },
        {
          ...VIP_MANAGE.PERSON.FOREVER,
          desc: '个人海量下载',
          tagClass: 'person-lifelong',
          mandateNum: '个人VIP：限学习交流，不可商用（共5项授权）',
          equitySimple: [
            {
              isHas: 0,
              label: '个人授权使用'
            },
            {
              isHas: 0,
              label: '图片包一键下载'
            },
            {
              isHas: true,
              label: '个人海量下载'
            },
            {
              isHas: true,
              label: '图片版权保障'
            },
            {
              isHas: true,
              label: '在线申请发票'
            },
            {
              isHas: true,
              label: '专属客服1对1'
            },
          ],
          scopePermission: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          downScope: ['个人VIP专享', '限时免费']
        },

        /*  {
            ...VIP_MANAGE.PERSON.YEAR_FIVE,
            desc: '个人海量下载',
            mandateNum: '个人VIP：限学习交流，不可商用（共5项授权）',
            equitySimple: [
              {
                isHas: 0,
                label: '商用授权使用'
              },
              {
                isHas: true,
                label: '个人海量下载'
              },
              {
                isHas: 0,
                label: '图片包一键下载'
              },
              {
                isHas: true,
                label: '图片版权保障'
              },
              {
                isHas: true,
                label: '在线申请发票'
              },
              {
                isHas: true,
                label: '专属客服1对1'
              },
            ],
            scopePermission: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
            ],
            downScope: ['个人VIP专享', '限时免费']
          },*/

        /*{
          ...VIP_MANAGE.PERSON.YEAR_FIVE,
          desc: '个人海量下载',
          tagClass: 'person-five-year',
          mandateNum: '个人VIP：限学习交流，不可商用（共5项授权）',
          equitySimple: [
            {
              isHas: 0,
              label: '个人授权使用'
            },
            {
              isHas: 0,
              label: '图片包一键下载'
            },
            {
              isHas: true,
              label: '个人海量下载'
            },
            {
              isHas: true,
              label: '图片版权保障'
            },
            {
              isHas: true,
              label: '在线申请发票'
            },
            {
              isHas: true,
              label: '专属客服1对1'
            },
          ],
          scopePermission: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          downScope: ['个人VIP专享', '限时免费']
        },*/
      ],
    },
    {
      ...VIP_MANAGE.COMPANY.INFO,
      title: '企业线上用途VIP',
      LIST: [
        {
          ...VIP_MANAGE.COMPANY.BASIC_100,
          versionName: '普通版-2个子帐号',
          // version: '基础版',
          version: '全站通套餐A',
          time: '6个月',
          downloadNum:"80张",
          dj:"",
          desc: '「普通版」商用授权书',
          personMax: '¥399/人',
          priceDesc: '全站海量下载+图片包',
          // tagClass: 'company-upgrade',
          mandateNum: '企业普通版VIP（共12项授权）',
          mandateNum2: `企业普通版VIP·共12项授权 ，  授权对象须注册资本≤500万 <p>（若注册资本＞500万，请购买更高版本）</p>`,
          ziben:"≤500万",
          equitySimple: [
            {
              isHas: 1,
              label: '企业商用授权'
            },
            {
              isHas: 1,
              label: '商用海量下载'
            },
            {
              isHas: 1,
              label: '图片包一键下载'
            },
            {
              isHas: 1,
              label: '图片版权保障'
            },
            {
              isHas: 1,
              label: '在线申请发票'
            },
            {
              isHas: 1,
              label: '专属客服1对1'
            },
          ],

          scopePermission: [
            1,
            1,
            1,
            1,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          quanyi:[
            {
              label:"新媒体用途",
              value: 1
            },
            {
              label:"电商用途",
              value: 1
            },
          ],

          isShow: true,
          downScope: ['企业VIP专享', '个人VIP专享', '限时免费']

        },
        {
          ...VIP_MANAGE.COMPANY.All_PURPOSE,

          versionName: '体验版-1个子帐号',
          // version: '普通版',
          version: '全站通套餐B',
          newName: '普通版',
          time: '1年',
          dj:"80%用户选择",
          desc: '「体验版」商用授权书',
          // tagClass: 'company-all',
          personMax: '¥999/人',
          priceDesc: '全站200张/年（含图片包）',
          mandateNum: '企业体验版VIP（共10项授权）',
          ziben:"≤100万",
          mandateNum2: `企业体验版VIP·共10项授权 ，  授权对象须注册资本≤100万 <p>（若注册资本＞100万，请购买更高版本）</p>`,
          equitySimple: [
            {
              isHas: 1,
              label: '企业商用授权'
            },
            {
              isHas: 1,
              label: '商用200张/年'
            },
            {
              isHas: 1,
              label: '图片包一键下载'
            },
            {
              isHas: 1,
              label: '图片版权保障'
            },
            {
              isHas: 1,
              label: '在线申请发票'
            },
            {
              isHas: 1,
              label: '专属客服1对1'
            },
          ],
          scopePermission: [
            1,
            1,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          isShow: true,
          downScope: ['企业VIP专享', '个人VIP专享', '限时免费'],
          quanyi:[
            {
              label:"新媒体用途",
              value: 1
            },
            {
              label:"电商用途",
              value: 1
            },
            {
              label:"享2千理赔金",
              value: 1
            }
          ]

        },
        {
          ...VIP_MANAGE.COMPANY.BASIC,
          versionName: '普通版-2个子帐号',
          version: '普通版',
          time: '6个月',
          dj:"1.0/天",
          desc: '「普通版」商用授权书',
          personMax: '¥399/人',
          dj:"性价比最高",
          priceDesc: '全站海量下载+图片包',
          // tagClass: 'company-upgrade',
          mandateNum: '企业普通版VIP（共12项授权）',
          mandateNum2: `企业普通版VIP·共12项授权 ，  授权对象须注册资本≤500万 <p>（若注册资本＞500万，请购买更高版本）</p>`,
          ziben:"≤500万",
          equitySimple: [
            {
              isHas: 1,
              label: '企业商用授权'
            },
            {
              isHas: 1,
              label: '商用海量下载'
            },
            {
              isHas: 1,
              label: '图片包一键下载'
            },
            {
              isHas: 1,
              label: '图片版权保障'
            },
            {
              isHas: 1,
              label: '在线申请发票'
            },
            {
              isHas: 1,
              label: '专属客服1对1'
            },
          ],

          scopePermission: [
            1,
            1,
            1,
            1,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          quanyi:[
            {
              label:"新媒体用途",
              value: 1
            },
            {
              label:"电商用途",
              value: 1
            },
            {
              label:"广告宣传",
              value: 1
            },
            {
              label:"线下用途",
              value: 1
            },
            {
              label:"享2万理赔金",
              value: 1
            }
          ],

          isShow: true,
          downScope: ['企业VIP专享', '个人VIP专享', '限时免费']

        },
        {
          ...VIP_MANAGE.COMPANY.MAJOR,
          versionName: '专业版-4个子帐号',
          // version: '专业版',
          version: '全站通套餐C',
          time: '6个月',

          desc: '「专业版」商用授权书',
          personMax: '¥2600/人',
          priceDesc: '全站海量下载+图片包',
          tagClass: 'company-gj',
          mandateNum: '企业专业版VIP（共13项授权）',
          mandateNum2: `企业专业版VIP·共13项授权 ，  授权对象须注册资本≤1000万 <p>（若注册资本＞1000万，请购买更高版本）</p>`,
          ziben:"≤1000万",
          equitySimple: [
            {
              isHas: 1,
              label: '企业商用授权'
            },
            {
              isHas: 1,
              label: '商用海量下载'
            },
            {
              isHas: 1,
              label: '图片包一键下载'
            },
            {
              isHas: 1,
              label: '图片版权保障'
            },
            {
              isHas: 1,
              label: '在线申请发票'
            },
            {
              isHas: 1,
              label: '专属客服1对1'
            },
          ],

          scopePermission: [
            1,
            1,
            1,
            1,
            1,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
          quanyi:[
            {
              label:"新媒体用途",
              value: 1
            },
            {
              label:"电商用途",
              value: 1
            },
            {
              label:"线下用途",
              value: 1
            },
            {
              label:"广告宣传",
              value: 1
            },

            {
              label:"传媒影视",
              value: 1
            },
            {
              label:"享3万理赔金",
              value: 1
            },
          ],

          isShow: true,
          downScope: ['企业VIP专享', '个人VIP专享', '限时免费']

        },
        {
          ...VIP_MANAGE.COMPANY.RELEASE,
          versionName: '发行版-8个子帐号',
          // version: '发行版',
          version: '全站通套餐D',
          time: '6个月',
          dj:"",
          desc: '「发行版」商用授权书',
          personMax: '¥5600/人',
          priceDesc: '全站海量下载+图片包',
          tagClass: 'company-gj',
          mandateNum: '企业发行版VIP（共14项授权）',
          mandateNum2: `企业发行版VIP·共14项授权 ，  授权对象须注册资本≤2000万 <p>（若注册资本＞2000万，请联系<a href=${SERVICE_LINK} target="_blank">&nbsp;&nbsp;客户经理&nbsp;&nbsp;</a>定制版本）</p>`,
          ziben:"≤1000万",
          equitySimple: [
            {
              isHas: 1,
              label: '企业商用授权'
            },
            {
              isHas: 1,
              label: '商用海量下载'
            },
            {
              isHas: 1,
              label: '图片包一键下载'
            },
            {
              isHas: 1,
              label: '图片版权保障'
            },
            {
              isHas: 1,
              label: '在线申请发票'
            },
            {
              isHas: 1,
              label: '专属客服1对1'
            },
          ],

          scopePermission: [
            1,
            1,
            1,
            1,
            1,
            1,
            1,
            0,
            0,
            0,
            0,
            0,
          ],
          quanyi:[
            {
              label:"新媒体用途",
              value: 1
            },
            {
              label:"电商用途",
              value: 1
            },
            {
              label:"线下用途",
              value: 1
            },
            {
              label:"广告宣传",
              value: 1
            },
            {
              label:"转售商品",
              value: 1
            },
            {
              label:"传媒影视",
              value: 1
            },
            {
              label:"享6万理赔金",
              value: 1
            }
          ],

          isShow: true,
          downScope: ['企业VIP专享', '个人VIP专享', '限时免费']

        },
      ]
    }
  ]
}
