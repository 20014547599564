<template>
  <div>
    <no-ssr>
      <cg-dialog ref="dialog" @close="close"
                 v-show="(isShowSurveyUser && isShow) || actIsShow"
      >
        <div
          slot="dialog_content"
          class="page-container">
          <SurveyUserIndustry @previousStep="nextStep" @nextStep="nextStep" :step="step" v-if="step === 1"/>
          <SurveyUserJob @previousStep="nextStep" @nextStep="nextStep" :step="step" v-if="step === 2"/>
          <SurveyUserAge @previousStep="nextStep" @submit="submit" :step="step" v-if="step === 3"/>
        </div>
      </cg-dialog>
    </no-ssr>
  </div>
</template>

<script>
import {
  SurveyUserIndustry,
  SurveyUserAge,
  SurveyUserJob,
} from './components/index'
import {getSurveyUserState, submitSurveyUser} from "@/api/survey-user";

import {fnUserSurvey} from "~/fn";
import {mapState} from "vuex";
import {completeTaskByFanClub} from "@/api/fans/club";

export default {
  name: "SurveyUser",
  components: {
    SurveyUserIndustry,
    SurveyUserAge,
    SurveyUserJob,
  },
  computed: {
    /*isShowSurveyUser() {
      return this.$store.state.surveyUser.isShowSurveyUser
    }*/
    ...mapState('surveyUser', ['isShowSurveyUser'])

  },
  data() {
    return {
      step: 1,
      isShow: false,

      actIsShow: false

    }
  },
  mounted() {
    this.initStep();
    this.isShow = !fnUserSurvey.getTodayIsWrite() ? true : false;
  },
  methods: {
    initStep() {
      let currStep = this.$localStorage.getItem('surveyUserStep');
      this.step = currStep != undefined ? currStep : 1;
    },

    nextStep(step) {
      this.step = step;
      this.$localStorage.setItem('surveyUserStep', step)
    },

    toggleShow(flag = true) {
      this.$store.commit('surveyUser/SET_IS_SHOW_SURVEY_USER', flag);
    },

    close() {
      this.actIsShow = false;
      this.$store.commit('surveyUser/SET_IS_SHOW_SURVEY_USER', false);
      this.clearSurveyUser();
      /* 当天不在弹出 */
      fnUserSurvey.updateTodayWriteState(1);
    },

    clearSurveyUser() {
      let date = new Date().getDate();
      this.$localStorage.remove('surveyUserState' + date);
      this.$localStorage.remove('surveyUserStep')
      this.$localStorage.remove('SurveyUser');
    },

    getSurveyUserState() {
      getSurveyUserState().then(res => {
        if (res.data.status === 2) {
          this.$localStorage.setItem('surveyUserState', 2);
        } else {
          this.$localStorage.remove('surveyUserState');
        }
      })
    },

    submit(tempSurveyUser) {
      submitSurveyUser(tempSurveyUser).then(res => {
        if (res.data.status === 1) {
          this.$myNotify.success(res.data.msg, () => {
            this.toggleShow(false);
            this.clearSurveyUser();
            this.getSurveyUserState();

            /* 检测是否是粉丝基础任务 */
            let {task_type, t_id} = this.$route.query;

            if (task_type && t_id) {
              completeTaskByFanClub({id: t_id}).then(res =>{
                let { status, data, message } = res.data;
                if (status ==200){
                  this.$myNotify.success("任务已完成")
                  this.actIsShow = false;
                  this.$router.push({
                    query:{}
                  })
                }else{
                  this.$myNotify.error(message)

                }
              })
            }


          });
        } else {
          this.$myNotify.error(res.data.msg);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.page-container {
  width: 830px;
  //height: 619px;
  background: #FFFFFF;
  //box-shadow: -4px 0px 12px 0px rgba(0, 0, 26, 0.06);
  //border-radius: 14px;
  padding: 57px 73px 48px;
  box-sizing: border-box;
}


</style>
