<template>
  <div class="layout-fixed-header">
    <div class="header-nav-wrap" ref="headerWrap">
<!--            <InviteBanner/>-->
<!--      <actBar @close="getPlaceholderHeight" />-->
      <div class="PublicHeader" id="HomeHeaderFixed">
        <PublicHeader
          id="Header"
          :params="params"
          :new-search-keyword="searchKeyword"
          :is-show-search="isShowSearch"
          @toMaterialList="toMaterialList"
          :picList="picList"
          :festivalList="festivalList"
          :solarTermsList="solarTermsList"
          :recommendList="recommendList"

          :threeDCategoryList="threeDCategoryList"
          :threeDRecommendList="threeDRecommendList"
          :festivalData="festivalData"
          :styleCategoryList="styleCategoryList"
          :styleRecommendList="styleRecommendList"
          :isFixedHeader="isFixedHeader"
          :isShowCompanyCase="isShowCompanyCase"
        />
      </div>
    </div>
    <div class="header-nav-wrap-placeholder" :style=" 'height: '+ placeholderHeight + 'px'"/>

    <nuxt/>
    <Footer />
    <GlobalSlot/>

    <no-ssr>
      <Login/>

      <Sidebar/>
      <PublicSlot />

      <!-- 新用户登录免费下载引导提示弹框 -->
      <!-- <NewUserFreeDownGuideModal/> -->
    </no-ssr>
  </div>

</template>

<script>
import {PublicHeader, Footer, Login} from "@/components/common/index.js"
import {InviteBanner} from "@/components/act/invite/index.js"
import {mapGetters} from "vuex";
import {getMaterialList} from "../api/material";
import {ObjectToArray} from "~/components/list/module";
import FeedbackBuoy from '@/components/common/feedback-buoy/feedback-buoy'
import PartTimeIcon from '@/components/common/part-time-icon';
import Sidebar from '@/components/common/sidebar'
import GlobalSlot from '@/components/common/global-slot'
import {PAGE_PATH} from "@/constant/PAGE_PATH";
import {getDropdownByDesignStyle, getDropdownByFestival, getDropdownByHot, getDropdownByThreeD} from "@/api/nav";
import NewUserFreeDownGuideModal from "@/components/_components/home/NewUserFreeDownGuideModal";
import {getDropDownByAll} from "~/api/home";

import PublicSlot from "@/components/_Public/PublicSlot";
// import actBar from "@/pages/act/618/actBar/index.vue";
import {fnLocalStorage} from "@/fn";
import {MODEL_ACT_618} from "@/constant/MODELS";
import Cookie from "js-cookie";


export default {
  name: "HeaderNav",
  components: {
    PublicHeader,
    InviteBanner,
    Footer,
    Login,
    FeedbackBuoy,
    PartTimeIcon,
    Sidebar,
    GlobalSlot,
    NewUserFreeDownGuideModal,

    PublicSlot,
    // actBar
  },
  data() {
    return {
      isShow: false,
      params: {
        pinyin: 0,
        home_keyword_id: 0,
        layout: 0,
        time_filter: 0,
        module: 0,
        desc: "default",
        page: 1,
      },
      searchKeyword: "",
      isShowSearch: false,
      picList: [],
      festivalList: [],
      solarTermsList: [],
      recommendList: [],
      threeDCategoryList: [],
      threeDRecommendList: [],
      festivalData: {},
      styleRecommendList: [],
      styleCategoryList: [],
      placeholderHeight: Cookie.get("headerHeight") || 80,
      isFixedHeader: true,
      isShowCompanyCase: true,
    }
  },
  computed: {
    ...mapGetters('layout', ['screenWidth', 'bannerHeight'])
  },

  mounted() {
    // let bannerHeight = this.$refs.headerWrap.offsetHeight
    // this.bannerHeight = bannerHeight
    // this.$store.commit('layout/SET_BANNER_HEIGHT', bannerHeight)

    let whiteList = [
      PAGE_PATH.TU_PIAN.DETAIL,
      PAGE_PATH.TU_PIAN.LIST,
      PAGE_PATH.TOPIC.COLLECTION
    ];

    for (let i = 0; i < whiteList.length; i++) {
      if (this.$route.path.indexOf(whiteList[i]) > -1) {
        this.isShowSearch = true
        break;
      } else {
        this.isShowSearch = false;
      }
    }
    if (this.$route.path.indexOf(PAGE_PATH.TU_PIAN.DETAIL) > -1) {
      this.$store.commit('base/SET_SEARCH_VAL', '')
    }

    this.getDropDownByALL();
    this.getPlaceholderHeight();

    /* 仅企业介绍页展示企业案例 */
    const isShowCompanyCase = this.$route.path.indexOf('/introduce/company') > -1;
    this.isShowCompanyCase = isShowCompanyCase;
  }
  ,
  methods: {
    close(){

    },
    getPlaceholderHeight(){
      setTimeout(()=>{
        const element = this.$refs.headerWrap;
        const headerHeight = element.offsetHeight;
        /* 读取本地存储的头部高度，一致则跳过，否则覆盖 */
        const localHeaderHeight = Cookie.get("headerHeight");

        console.log({
          headerHeight,
          localHeaderHeight
        })
        this.$store.commit('_Public/Header/setHeaderHeight',headerHeight)
        if (headerHeight != localHeaderHeight){
          Cookie.set("headerHeight",headerHeight)
          this.placeholderHeight = headerHeight;
          this.$store.commit('_Public/Header/setHeaderHeight',headerHeight)

        }
      },300)
    },
    getDropDownByALL(){
      getDropDownByAll().then(res =>{
        const { status , data } = res.data;
        if(status == 1){
          this.$store.commit('_Public/Header/setDropDownDataByAll',data)
        }
      })

    },
    toMaterialList(path) {
      if (this.$route.path.indexOf('/list') > -1) {
        this.materialList = [];
        this.getMaterialList(this.params)
      } else {

      }
    }
    ,
    getMaterialList(tempParams) {
      getMaterialList(tempParams).then(res => {
        let {list, keyword, total_page} = res.data;
        this.searchKeyword = keyword;
        if (Array.isArray(list) && list.length === 0) {
          this.materialList = [];
          this.reqState = false
        } else {
          this.materialList = ObjectToArray(list);
          this.reqState = true
        }
      })
    }
    ,
  }
}
</script>

<style lang="scss" scoped>
@import "components/common/header/common/header";

.header-nav-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
}


#HomeHeaderFixed {
  //top: 0 !important;
  position: static !important;
}

.header-nav-wrap-placeholder{
  transition: all 0.3s;
}
</style>


<!--
  Readme
  含固定头部和底部
-->

