<template>
  <div class="ScrollLogo inner_center ">
    <div class="ScrollLogo-item ">

      <div class="login-box" id="colee_left" style="overflow:hidden;width:100%">
        <table cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td id="colee_left1" valign="top">
              <table cellpadding="2" cellspacing="0" border="0">
                <tr align="center">
                  <td v-for="(vo,idx) in 9">
                    <!--                    <i
                                          class="icon_logo" :class=" 'icon_logo-'+ (idx) "/>-->
                    <img class="icon_logo"
                         v-lazy=" 'https://static.588ku.com/chaotu/home/cooperate-company/new/logo@' + (idx+1) +'.png' "
                         alt="">
                  </td>

                </tr>
              </table>
            </td>
            <td id="colee_left2" valign="top"></td>
          </tr>
        </table>
      </div>

      <div class="login-box1" id="colee_right" style="overflow:hidden;width:100%">
        <table cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td id="colee_right1" valign="top">
              <table cellpadding="2" cellspacing="0" border="0">
                <tr align="center">
                  <td v-for="(vo,idx) in [9,10,11,12,13,14,15,16,17,18,19]">
                    <!--                    <i
                                          class="icon_logo" :class=" 'icon_logo-'+ (vo) "/>-->
                    <img class="icon_logo"
                         v-lazy=" 'https://static.588ku.com/chaotu/home/cooperate-company/new/logo@' + (8+idx) +'.png' "
                         alt="">

                  </td>
                </tr>
              </table>
            </td>
            <td id="colee_right2" valign="top"></td>
          </tr>
        </table>
      </div>

      <div class="login-box2" id="lcolee_left" style="overflow:hidden;width:100%">
        <table cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td id="lcolee_left1" valign="top">
              <table cellpadding="2" cellspacing="0" border="0">
                <tr align="center">
                  <td v-for="(vo,idx) in [19,20,21,22,23,24,25,26,27,28]">
                    <!--                    <i
                                          class="icon_logo" :class=" 'icon_logo-'+ (vo) "/>-->
                    <img class="icon_logo"
                         v-lazy=" 'https://static.588ku.com/chaotu/home/cooperate-company/new/logo@' + (18+idx) +'.png' "
                         alt="">

                  </td>

                </tr>
              </table>
            </td>
            <td id="lcolee_left2" valign="top"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScrollLogo",
  mounted() {
    this.initLeft();
    this.initLeft2();
    this.initRight();
  },
  data() {
    return {
      timer1: null,
    }
  },
  methods: {
    initLeft() {
      //使用div时，请保证colee_left2与colee_left1是在同一行上.
      var speed = 30 //速度数值越大速度越慢
      var colee_left2 = document.getElementById("colee_left2");
      var colee_left1 = document.getElementById("colee_left1");
      var colee_left = document.getElementById("colee_left");
      // colee_left2.innerHTML = colee_left1.innerHTML

      colee_left2.innerHTML = this.clone(colee_left1)

      function Marquee3() {
        if (colee_left2.offsetWidth - colee_left.scrollLeft <= 0) //offsetWidth 是对象的可见宽度
          colee_left.scrollLeft -= colee_left1.offsetWidth //scrollWidth 是对象的实际内容的宽，不包边线宽度
        else {
          colee_left.scrollLeft++;
        }
      }

      let MyMar3 = setInterval(Marquee3, speed)
      colee_left.onmouseover = function () {
        clearInterval(MyMar3)
      }
      colee_left.onmouseout = function () {
        MyMar3 = setInterval(Marquee3, speed)
      }
    },
    initLeft2() {
      //使用div时，请保证colee_left2与colee_left1是在同一行上.
      var speed = 30 //速度数值越大速度越慢
      var colee_left2 = document.getElementById("lcolee_left2");
      var colee_left1 = document.getElementById("lcolee_left1");
      var colee_left = document.getElementById("lcolee_left");
      // colee_left2.innerHTML = colee_left1.innerHTML

      colee_left2.innerHTML = this.clone(colee_left1)

      function Marquee3() {
        if (colee_left2.offsetWidth - colee_left.scrollLeft <= 0) //offsetWidth 是对象的可见宽度
          colee_left.scrollLeft -= colee_left1.offsetWidth //scrollWidth 是对象的实际内容的宽，不包边线宽度
        else {
          colee_left.scrollLeft++;
        }
      }

      let MyMar5 = setInterval(Marquee3, speed)
      colee_left.onmouseover = function () {
        clearInterval(MyMar5)
      }
      colee_left.onmouseout = function () {
        MyMar5 = setInterval(Marquee3, speed)
      }
    },
    initRight() {
      var speed = 30 //速度数值越大速度越慢
      var colee_right2 = document.getElementById("colee_right2");
      var colee_right1 = document.getElementById("colee_right1");
      var colee_right = document.getElementById("colee_right");
      // colee_right2.innerHTML = colee_right1.innerHTML

      colee_right2.innerHTML = this.clone(colee_right1)


      function Marquee4() {
        if (colee_right.scrollLeft <= 0)
          colee_right.scrollLeft += colee_right2.offsetWidth
        else {
          colee_right.scrollLeft--;
        }
      }

      let MyMar4 = setInterval(Marquee4, speed)
      colee_right.onmouseover = function () {
        clearInterval(MyMar4)
      }
      colee_right.onmouseout = function () {
        MyMar4 = setInterval(Marquee4, speed)
      }
    },


    clone(origin) {
      var originNew = origin.cloneNode();
      //进行attr属性的复制过来  同理，想在克隆的时候复制其他同样可以加进来
      if (origin.attributes != null) {
        for (var index = 0; index < origin.attributes.length; index++) {
          var attr = origin.attributes[index];
          var name = attr.name;
          var value = attr.value;
          originNew.setAttribute(name, value);
        }
      }

      //克隆子标签
      var originChildNodes = origin.childNodes;
      for (var index = 0; index < originChildNodes.length; index++) {
        var node = originChildNodes[index];
        if (node != null) {
          //递归进行子标签的克隆
          var childNode = this.clone(node);
          originNew.appendChild(childNode);
        }
      }
      return originNew;
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin IconLogo($index,$count) {
  @for $i from $index through $count {
    .icon_logo-#{$i} {
      @include BgImage(205px, 87px, 'home/cooperate-company/new/logo@#{$i+1}.png');
      margin-right: 9px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.icon_logo {
  display: inline-block;
  width: 205px;
  height: 87px;
  margin-right: 9px;

  &:last-child {
    margin-right: 0;
  }
}


.ScrollLogo-item {
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    width: 120px;
    height: 273px;
    background: linear-gradient(360deg, #F5F6FA 0%, #FCFCFD 100%);
    filter: blur(20px);
    @include Position(1, 2, -60px, 0);
    z-index: 9;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 120px;
    height: 273px;
    background: linear-gradient(360deg, #F5F6FA 0%, #FCFCFD 100%);
    filter: blur(20px);
    @include Position(1, 5, -60px, 0);
    z-index: 9;
  }
}

.login-box {
  @include IconLogo(0, 8);
  display: flex;
  flex-wrap: nowrap;

  i {
    flex-shrink: 0;
  }
}

.login-box1 {
  @include IconLogo(9, 19);
  display: flex;
  flex-wrap: nowrap;

  i {
    flex-shrink: 0;
  }
}

.login-box2 {
  @include IconLogo(19, 27);
  display: flex;
  flex-wrap: nowrap;

  i {
    flex-shrink: 0;
  }
}

.login-box,
.login-box1,
.login-box2 {
  margin-bottom: 6px;
  position: relative;
  z-index: 8;
}

</style>
