import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _323ff2de = () => interopDefault(import('../pages/3d/index.vue' /* webpackChunkName: "pages/3d/index" */))
const _f7f6d46a = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _27675c26 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _6a6d1115 = () => interopDefault(import('../pages/creator/index.vue' /* webpackChunkName: "pages/creator/index" */))
const _7198e1c1 = () => interopDefault(import('../pages/error-page/index.vue' /* webpackChunkName: "pages/error-page/index" */))
const _4d4f2400 = () => interopDefault(import('../pages/exchange-code/index.vue' /* webpackChunkName: "pages/exchange-code/index" */))
const _356380cd = () => interopDefault(import('../pages/fans/index.vue' /* webpackChunkName: "pages/fans/index" */))
const _29fef34c = () => interopDefault(import('../pages/fans-club/index.vue' /* webpackChunkName: "pages/fans-club/index" */))
const _2abd1952 = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _340acac8 = () => interopDefault(import('../pages/introduce/index.vue' /* webpackChunkName: "pages/introduce/index" */))
const _72f14754 = () => interopDefault(import('../pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _9dbd0d8c = () => interopDefault(import('../pages/invite-h5/index.vue' /* webpackChunkName: "pages/invite-h5/index" */))
const _21bfc1c7 = () => interopDefault(import('../pages/invite-member/index.vue' /* webpackChunkName: "pages/invite-member/index" */))
const _37900ad9 = () => interopDefault(import('../pages/module.js' /* webpackChunkName: "pages/module" */))
const _0c325810 = () => interopDefault(import('../pages/part-time/index.vue' /* webpackChunkName: "pages/part-time/index" */))
const _b6097eb6 = () => interopDefault(import('../pages/protocol/index.vue' /* webpackChunkName: "pages/protocol/index" */))
const _162e76f8 = () => interopDefault(import('../pages/red-book-share/index.vue' /* webpackChunkName: "pages/red-book-share/index" */))
const _04ed8f1f = () => interopDefault(import('../pages/test/index.vue' /* webpackChunkName: "pages/test/index" */))
const _0cabd77e = () => interopDefault(import('../pages/timeline-update/index.vue' /* webpackChunkName: "pages/timeline-update/index" */))
const _6eb37f05 = () => interopDefault(import('../pages/upgrade/index.vue' /* webpackChunkName: "pages/upgrade/index" */))
const _5588b0c0 = () => interopDefault(import('../pages/user-center/index.vue' /* webpackChunkName: "pages/user-center/index" */))
const _5b850dc9 = () => interopDefault(import('../pages/act/618/index.vue' /* webpackChunkName: "pages/act/618/index" */))
const _283b74c6 = () => interopDefault(import('../pages/act/hb-capture/index.vue' /* webpackChunkName: "pages/act/hb-capture/index" */))
const _06b46612 = () => interopDefault(import('../pages/calendar/index2.vue' /* webpackChunkName: "pages/calendar/index2" */))
const _57b3df6c = () => interopDefault(import('../pages/calendar/module.js' /* webpackChunkName: "pages/calendar/module" */))
const _cfa2bdc6 = () => interopDefault(import('../pages/collection/downloadMixin.js' /* webpackChunkName: "pages/collection/downloadMixin" */))
const _4aee8b75 = () => interopDefault(import('../pages/collection/mixin.js' /* webpackChunkName: "pages/collection/mixin" */))
const _f9b550d6 = () => interopDefault(import('../pages/collection/share-code/index.vue' /* webpackChunkName: "pages/collection/share-code/index" */))
const _7445971c = () => interopDefault(import('../pages/company/case-collection/index.vue' /* webpackChunkName: "pages/company/case-collection/index" */))
const _29425733 = () => interopDefault(import('../pages/company/collect-record/index.vue' /* webpackChunkName: "pages/company/collect-record/index" */))
const _fec7bebc = () => interopDefault(import('../pages/company/main-certificate/index.vue' /* webpackChunkName: "pages/company/main-certificate/index" */))
const _201c3815 = () => interopDefault(import('../pages/company/mandate-record/index.vue' /* webpackChunkName: "pages/company/mandate-record/index" */))
const _3d6f3575 = () => interopDefault(import('../pages/company/pay-record/index.vue' /* webpackChunkName: "pages/company/pay-record/index" */))
const _01b71928 = () => interopDefault(import('../pages/company/team-manage/index.vue' /* webpackChunkName: "pages/company/team-manage/index" */))
const _cd46ca6a = () => interopDefault(import('../pages/components/HelpStatusModal.vue' /* webpackChunkName: "pages/components/HelpStatusModal" */))
const _3bbcc786 = () => interopDefault(import('../pages/creator/design-guide/index.vue' /* webpackChunkName: "pages/creator/design-guide/index" */))
const _154c5550 = () => interopDefault(import('../pages/creator/pic/index.vue' /* webpackChunkName: "pages/creator/pic/index" */))
const _7c5800cd = () => interopDefault(import('../pages/creator/sign/index.vue' /* webpackChunkName: "pages/creator/sign/index" */))
const _f07982d6 = () => interopDefault(import('../pages/creator/task/index.vue' /* webpackChunkName: "pages/creator/task/index" */))
const _40331d1d = () => interopDefault(import('../pages/creator/user-center/index.vue' /* webpackChunkName: "pages/creator/user-center/index" */))
const _13f3eaf0 = () => interopDefault(import('../pages/creator/works/index.vue' /* webpackChunkName: "pages/creator/works/index" */))
const _eaa12f40 = () => interopDefault(import('../pages/creator/works-manage/index.vue' /* webpackChunkName: "pages/creator/works-manage/index" */))
const _1e159bbc = () => interopDefault(import('../pages/detail/mixin.js' /* webpackChunkName: "pages/detail/mixin" */))
const _663d6987 = () => interopDefault(import('../pages/enterprise/case/index.vue' /* webpackChunkName: "pages/enterprise/case/index" */))
const _e6b853e8 = () => interopDefault(import('../pages/fans/festival-lottery/index.vue' /* webpackChunkName: "pages/fans/festival-lottery/index" */))
const _94733c7a = () => interopDefault(import('../pages/fans/member-demand-pool/index.vue' /* webpackChunkName: "pages/fans/member-demand-pool/index" */))
const _08125494 = () => interopDefault(import('../pages/introduce/company/index.vue' /* webpackChunkName: "pages/introduce/company/index" */))
const _71273832 = () => interopDefault(import('../pages/introduce/company-plus/index.vue' /* webpackChunkName: "pages/introduce/company-plus/index" */))
const _0a0c1a8d = () => interopDefault(import('../pages/introduce/multiple/index.vue' /* webpackChunkName: "pages/introduce/multiple/index" */))
const _772ff52c = () => interopDefault(import('../pages/invite/team-role.vue' /* webpackChunkName: "pages/invite/team-role" */))
const _7c1ddf12 = () => interopDefault(import('../pages/invoice/module.js' /* webpackChunkName: "pages/invoice/module" */))
const _6adeb13a = () => interopDefault(import('../pages/invoice/write.vue' /* webpackChunkName: "pages/invoice/write" */))
const _69c9ed26 = () => interopDefault(import('../pages/member/license-vip.vue' /* webpackChunkName: "pages/member/license-vip" */))
const _403cbc9e = () => interopDefault(import('../pages/part-time/module.js' /* webpackChunkName: "pages/part-time/module" */))
const _72b7a8f8 = () => interopDefault(import('../pages/pay/act-company/index.vue' /* webpackChunkName: "pages/pay/act-company/index" */))
const _30aad386 = () => interopDefault(import('../pages/pay/copy.vue' /* webpackChunkName: "pages/pay/copy" */))
const _3ee1ba10 = () => interopDefault(import('../pages/pay/edu/index.vue' /* webpackChunkName: "pages/pay/edu/index" */))
const _311eb732 = () => interopDefault(import('../pages/pay/module.js' /* webpackChunkName: "pages/pay/module" */))
const _22214596 = () => interopDefault(import('../pages/protocol/components/index.vue' /* webpackChunkName: "pages/protocol/components/index" */))
const _3d00b36e = () => interopDefault(import('../pages/survey/user/index.vue' /* webpackChunkName: "pages/survey/user/index" */))
const _c1a1bfee = () => interopDefault(import('../pages/tags/fnTag.js' /* webpackChunkName: "pages/tags/fnTag" */))
const _ce4803ba = () => interopDefault(import('../pages/test/index2.vue' /* webpackChunkName: "pages/test/index2" */))
const _be4086b0 = () => interopDefault(import('../pages/topic/mixin.js' /* webpackChunkName: "pages/topic/mixin" */))
const _e6fc04b8 = () => interopDefault(import('../pages/tupian/model.js' /* webpackChunkName: "pages/tupian/model" */))
const _460a213c = () => interopDefault(import('../pages/user-center/code-record/index.vue' /* webpackChunkName: "pages/user-center/code-record/index" */))
const _638dfb59 = () => interopDefault(import('../pages/user-center/collect-record/index.vue' /* webpackChunkName: "pages/user-center/collect-record/index" */))
const _204160c9 = () => interopDefault(import('../pages/user-center/coupon-record/index.vue' /* webpackChunkName: "pages/user-center/coupon-record/index" */))
const _7c2139b2 = () => interopDefault(import('../pages/user-center/download-record/index.vue' /* webpackChunkName: "pages/user-center/download-record/index" */))
const _3718dee6 = () => interopDefault(import('../pages/user-center/leaflet-record/index.vue' /* webpackChunkName: "pages/user-center/leaflet-record/index" */))
const _7f673761 = () => interopDefault(import('../pages/user-center/user-info/index.vue' /* webpackChunkName: "pages/user-center/user-info/index" */))
const _7dcbfdc6 = () => interopDefault(import('../pages/user-center/vip-record/index.vue' /* webpackChunkName: "pages/user-center/vip-record/index" */))
const _6e5c86ae = () => interopDefault(import('../pages/act/618/2.vue' /* webpackChunkName: "pages/act/618/2" */))
const _6e4057ac = () => interopDefault(import('../pages/act/618/3.vue' /* webpackChunkName: "pages/act/618/3" */))
const _58ffb7bd = () => interopDefault(import('../pages/act/618/actBar/index.vue' /* webpackChunkName: "pages/act/618/actBar/index" */))
const _7e73b436 = () => interopDefault(import('../pages/act/618/actRule.vue' /* webpackChunkName: "pages/act/618/actRule" */))
const _bc378844 = () => interopDefault(import('../pages/act/618/actWait.vue' /* webpackChunkName: "pages/act/618/actWait" */))
const _2f34576a = () => interopDefault(import('../pages/act/618/module.js' /* webpackChunkName: "pages/act/618/module" */))
const _80dce184 = () => interopDefault(import('../pages/act/hb-capture/module.js' /* webpackChunkName: "pages/act/hb-capture/module" */))
const _02eb045f = () => interopDefault(import('../pages/company/team-manage/invite.vue' /* webpackChunkName: "pages/company/team-manage/invite" */))
const _0f891d00 = () => interopDefault(import('../pages/company/team-manage/join.vue' /* webpackChunkName: "pages/company/team-manage/join" */))
const _3be48ecc = () => interopDefault(import('../pages/company/team-manage/role.vue' /* webpackChunkName: "pages/company/team-manage/role" */))
const _3eb7a3b8 = () => interopDefault(import('../pages/creator/sign/sign-progress.vue' /* webpackChunkName: "pages/creator/sign/sign-progress" */))
const _567e63dc = () => interopDefault(import('../pages/creator/sign/sign-renew.vue' /* webpackChunkName: "pages/creator/sign/sign-renew" */))
const _10a22264 = () => interopDefault(import('../pages/creator/sign/sign-write.vue' /* webpackChunkName: "pages/creator/sign/sign-write" */))
const _6cbb3922 = () => interopDefault(import('../pages/creator/user-center/certificate.vue' /* webpackChunkName: "pages/creator/user-center/certificate" */))
const _eb174638 = () => interopDefault(import('../pages/creator/works-manage/WorkDrawer.vue' /* webpackChunkName: "pages/creator/works-manage/WorkDrawer" */))
const _5ea9499d = () => interopDefault(import('../pages/fans/common/mixin.js' /* webpackChunkName: "pages/fans/common/mixin" */))
const _777d4c24 = () => interopDefault(import('../pages/fans/demand-pool/mixin.js' /* webpackChunkName: "pages/fans/demand-pool/mixin" */))
const _448937a4 = () => interopDefault(import('../pages/introduce/multiple/module.js' /* webpackChunkName: "pages/introduce/multiple/module" */))
const _978326ce = () => interopDefault(import('../pages/pay/edu/module.js' /* webpackChunkName: "pages/pay/edu/module" */))
const _2f92f631 = () => interopDefault(import('../pages/pay/enterprise-recharge/coupon-pulldown.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/coupon-pulldown" */))
const _5ce55236 = () => interopDefault(import('../pages/pay/enterprise-recharge/module.js' /* webpackChunkName: "pages/pay/enterprise-recharge/module" */))
const _1e2e610a = () => interopDefault(import('../pages/pay/enterprise-recharge/transfer/index.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/transfer/index" */))
const _1c422aa0 = () => interopDefault(import('../pages/pay/leaflet/module.js' /* webpackChunkName: "pages/pay/leaflet/module" */))
const _b29a5d1c = () => interopDefault(import('../pages/pay/personal-recharge/coupon-pulldown.vue' /* webpackChunkName: "pages/pay/personal-recharge/coupon-pulldown" */))
const _1ee11ab7 = () => interopDefault(import('../pages/pay/personal-recharge/module.js' /* webpackChunkName: "pages/pay/personal-recharge/module" */))
const _6c808af2 = () => interopDefault(import('../pages/protocol/common/protocol-extend.js' /* webpackChunkName: "pages/protocol/common/protocol-extend" */))
const _019a2cfe = () => interopDefault(import('../pages/protocol/components/about-company.vue' /* webpackChunkName: "pages/protocol/components/about-company" */))
const _22c06f29 = () => interopDefault(import('../pages/protocol/components/common-problem.vue' /* webpackChunkName: "pages/protocol/components/common-problem" */))
const _286e2ea6 = () => interopDefault(import('../pages/protocol/components/login.vue' /* webpackChunkName: "pages/protocol/components/login" */))
const _48a82261 = () => interopDefault(import('../pages/protocol/components/vip.vue' /* webpackChunkName: "pages/protocol/components/vip" */))
const _fcf51046 = () => interopDefault(import('../pages/survey/user/components/index.js' /* webpackChunkName: "pages/survey/user/components/index" */))
const _95a2202c = () => interopDefault(import('../pages/survey/user/module.js' /* webpackChunkName: "pages/survey/user/module" */))
const _7eba5544 = () => interopDefault(import('../pages/tupian/common/mixin.js' /* webpackChunkName: "pages/tupian/common/mixin" */))
const _cc74f4c8 = () => interopDefault(import('../pages/tupian/common/model.js' /* webpackChunkName: "pages/tupian/common/model" */))
const _373d44fa = () => interopDefault(import('../pages/user-center/collect-record/module.js' /* webpackChunkName: "pages/user-center/collect-record/module" */))
const _181eab2c = () => interopDefault(import('../pages/user-center/coupon-record/module.js' /* webpackChunkName: "pages/user-center/coupon-record/module" */))
const _d4c2a670 = () => interopDefault(import('../pages/user-center/download-record/module.js' /* webpackChunkName: "pages/user-center/download-record/module" */))
const _517b4767 = () => interopDefault(import('../pages/user-center/vip-record/module.js' /* webpackChunkName: "pages/user-center/vip-record/module" */))
const _1973bb3e = () => interopDefault(import('../pages/act/618/actBar/bar.vue' /* webpackChunkName: "pages/act/618/actBar/bar" */))
const _27a5de76 = () => interopDefault(import('../pages/introduce/multiple/components/authorized-scope.vue' /* webpackChunkName: "pages/introduce/multiple/components/authorized-scope" */))
const _619a40da = () => interopDefault(import('../pages/introduce/multiple/components/Question.vue' /* webpackChunkName: "pages/introduce/multiple/components/Question" */))
const _76a7a706 = () => interopDefault(import('../pages/introduce/multiple/components/vip-card-group.vue' /* webpackChunkName: "pages/introduce/multiple/components/vip-card-group" */))
const _032b75c2 = () => interopDefault(import('../pages/survey/user/components/age.vue' /* webpackChunkName: "pages/survey/user/components/age" */))
const _dbf8ed24 = () => interopDefault(import('../pages/survey/user/components/industry.vue' /* webpackChunkName: "pages/survey/user/components/industry" */))
const _30024e46 = () => interopDefault(import('../pages/survey/user/components/job.vue' /* webpackChunkName: "pages/survey/user/components/job" */))
const _23145821 = () => interopDefault(import('../pages/user-center/download-record/components/CertificationModal.vue' /* webpackChunkName: "pages/user-center/download-record/components/CertificationModal" */))
const _9fb462d2 = () => interopDefault(import('../pages/enterprise/case/detail/_id.vue' /* webpackChunkName: "pages/enterprise/case/detail/_id" */))
const _0eb937a7 = () => interopDefault(import('../pages/company/pay/_id.vue' /* webpackChunkName: "pages/company/pay/_id" */))
const _619f9139 = () => interopDefault(import('../pages/fans/demand-detail/_id.vue' /* webpackChunkName: "pages/fans/demand-detail/_id" */))
const _c4e10148 = () => interopDefault(import('../pages/fans/demand-detail/_mixin.js' /* webpackChunkName: "pages/fans/demand-detail/_mixin" */))
const _8a138978 = () => interopDefault(import('../pages/fans/demand-pool/_id.vue' /* webpackChunkName: "pages/fans/demand-pool/_id" */))
const _8bb8714e = () => interopDefault(import('../pages/fans/member-demand-pool-detail/_id.vue' /* webpackChunkName: "pages/fans/member-demand-pool-detail/_id" */))
const _56672c3d = () => interopDefault(import('../pages/pay/enterprise-recharge/_id.vue' /* webpackChunkName: "pages/pay/enterprise-recharge/_id" */))
const _11131727 = () => interopDefault(import('../pages/pay/leaflet/_id.vue' /* webpackChunkName: "pages/pay/leaflet/_id" */))
const _7176d720 = () => interopDefault(import('../pages/pay/package/_id.vue' /* webpackChunkName: "pages/pay/package/_id" */))
const _6a0d20fe = () => interopDefault(import('../pages/pay/personal-recharge/_id.vue' /* webpackChunkName: "pages/pay/personal-recharge/_id" */))
const _50d07774 = () => interopDefault(import('../pages/pay/upgrade/_id.vue' /* webpackChunkName: "pages/pay/upgrade/_id" */))
const _3da74dd3 = () => interopDefault(import('../pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _640a9d86 = () => interopDefault(import('../pages/detail/_id.vue' /* webpackChunkName: "pages/detail/_id" */))
const _13508222 = () => interopDefault(import('../pages/invite-h5/_id.vue' /* webpackChunkName: "pages/invite-h5/_id" */))
const _57cc7c3e = () => interopDefault(import('../pages/invite/_id.vue' /* webpackChunkName: "pages/invite/_id" */))
const _72cb3ea4 = () => interopDefault(import('../pages/pay-company-plus/_id.vue' /* webpackChunkName: "pages/pay-company-plus/_id" */))
const _126da039 = () => interopDefault(import('../pages/pay/_id.vue' /* webpackChunkName: "pages/pay/_id" */))
const _3a16d594 = () => interopDefault(import('../pages/pic-package/_id.vue' /* webpackChunkName: "pages/pic-package/_id" */))
const _0f3a45ce = () => interopDefault(import('../pages/tags/_id.vue' /* webpackChunkName: "pages/tags/_id" */))
const _d9fcab80 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _78b3f51c = () => interopDefault(import('../pages/tupian/_id.vue' /* webpackChunkName: "pages/tupian/_id" */))
const _d56ff100 = () => interopDefault(import('../pages/venue/_id.vue' /* webpackChunkName: "pages/venue/_id" */))
const _63e0c138 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/3d",
    component: _323ff2de,
    name: "3d"
  }, {
    path: "/calendar",
    component: _f7f6d46a,
    name: "calendar"
  }, {
    path: "/company",
    component: _27675c26,
    name: "company"
  }, {
    path: "/creator",
    component: _6a6d1115,
    name: "creator"
  }, {
    path: "/error-page",
    component: _7198e1c1,
    name: "error-page"
  }, {
    path: "/exchange-code",
    component: _4d4f2400,
    name: "exchange-code"
  }, {
    path: "/fans",
    component: _356380cd,
    name: "fans"
  }, {
    path: "/fans-club",
    component: _29fef34c,
    name: "fans-club"
  }, {
    path: "/feedback",
    component: _2abd1952,
    name: "feedback"
  }, {
    path: "/introduce",
    component: _340acac8,
    name: "introduce"
  }, {
    path: "/invite",
    component: _72f14754,
    name: "invite"
  }, {
    path: "/invite-h5",
    component: _9dbd0d8c,
    name: "invite-h5"
  }, {
    path: "/invite-member",
    component: _21bfc1c7,
    name: "invite-member"
  }, {
    path: "/module",
    component: _37900ad9,
    name: "module"
  }, {
    path: "/part-time",
    component: _0c325810,
    name: "part-time"
  }, {
    path: "/protocol",
    component: _b6097eb6,
    name: "protocol"
  }, {
    path: "/red-book-share",
    component: _162e76f8,
    name: "red-book-share"
  }, {
    path: "/test",
    component: _04ed8f1f,
    name: "test"
  }, {
    path: "/timeline-update",
    component: _0cabd77e,
    name: "timeline-update"
  }, {
    path: "/upgrade",
    component: _6eb37f05,
    name: "upgrade"
  }, {
    path: "/user-center",
    component: _5588b0c0,
    name: "user-center"
  }, {
    path: "/act/618",
    component: _5b850dc9,
    name: "act-618"
  }, {
    path: "/act/hb-capture",
    component: _283b74c6,
    name: "act-hb-capture"
  }, {
    path: "/calendar/index2",
    component: _06b46612,
    name: "calendar-index2"
  }, {
    path: "/calendar/module",
    component: _57b3df6c,
    name: "calendar-module"
  }, {
    path: "/collection/downloadMixin",
    component: _cfa2bdc6,
    name: "collection-downloadMixin"
  }, {
    path: "/collection/mixin",
    component: _4aee8b75,
    name: "collection-mixin"
  }, {
    path: "/collection/share-code",
    component: _f9b550d6,
    name: "collection-share-code"
  }, {
    path: "/company/case-collection",
    component: _7445971c,
    name: "company-case-collection"
  }, {
    path: "/company/collect-record",
    component: _29425733,
    name: "company-collect-record"
  }, {
    path: "/company/main-certificate",
    component: _fec7bebc,
    name: "company-main-certificate"
  }, {
    path: "/company/mandate-record",
    component: _201c3815,
    name: "company-mandate-record"
  }, {
    path: "/company/pay-record",
    component: _3d6f3575,
    name: "company-pay-record"
  }, {
    path: "/company/team-manage",
    component: _01b71928,
    name: "company-team-manage"
  }, {
    path: "/components/HelpStatusModal",
    component: _cd46ca6a,
    name: "components-HelpStatusModal"
  }, {
    path: "/creator/design-guide",
    component: _3bbcc786,
    name: "creator-design-guide"
  }, {
    path: "/creator/pic",
    component: _154c5550,
    name: "creator-pic"
  }, {
    path: "/creator/sign",
    component: _7c5800cd,
    name: "creator-sign"
  }, {
    path: "/creator/task",
    component: _f07982d6,
    name: "creator-task"
  }, {
    path: "/creator/user-center",
    component: _40331d1d,
    name: "creator-user-center"
  }, {
    path: "/creator/works",
    component: _13f3eaf0,
    name: "creator-works"
  }, {
    path: "/creator/works-manage",
    component: _eaa12f40,
    name: "creator-works-manage"
  }, {
    path: "/detail/mixin",
    component: _1e159bbc,
    name: "detail-mixin"
  }, {
    path: "/enterprise/case",
    component: _663d6987,
    name: "enterprise-case"
  }, {
    path: "/fans/festival-lottery",
    component: _e6b853e8,
    name: "fans-festival-lottery"
  }, {
    path: "/fans/member-demand-pool",
    component: _94733c7a,
    name: "fans-member-demand-pool"
  }, {
    path: "/introduce/company",
    component: _08125494,
    name: "introduce-company"
  }, {
    path: "/introduce/company-plus",
    component: _71273832,
    name: "introduce-company-plus"
  }, {
    path: "/introduce/multiple",
    component: _0a0c1a8d,
    name: "introduce-multiple"
  }, {
    path: "/invite/team-role",
    component: _772ff52c,
    name: "invite-team-role"
  }, {
    path: "/invoice/module",
    component: _7c1ddf12,
    name: "invoice-module"
  }, {
    path: "/invoice/write",
    component: _6adeb13a,
    name: "invoice-write"
  }, {
    path: "/member/license-vip",
    component: _69c9ed26,
    name: "member-license-vip"
  }, {
    path: "/part-time/module",
    component: _403cbc9e,
    name: "part-time-module"
  }, {
    path: "/pay/act-company",
    component: _72b7a8f8,
    name: "pay-act-company"
  }, {
    path: "/pay/copy",
    component: _30aad386,
    name: "pay-copy"
  }, {
    path: "/pay/edu",
    component: _3ee1ba10,
    name: "pay-edu"
  }, {
    path: "/pay/module",
    component: _311eb732,
    name: "pay-module"
  }, {
    path: "/protocol/components",
    component: _22214596,
    name: "protocol-components"
  }, {
    path: "/survey/user",
    component: _3d00b36e,
    name: "survey-user"
  }, {
    path: "/tags/fnTag",
    component: _c1a1bfee,
    name: "tags-fnTag"
  }, {
    path: "/test/index2",
    component: _ce4803ba,
    name: "test-index2"
  }, {
    path: "/topic/mixin",
    component: _be4086b0,
    name: "topic-mixin"
  }, {
    path: "/tupian/model",
    component: _e6fc04b8,
    name: "tupian-model"
  }, {
    path: "/user-center/code-record",
    component: _460a213c,
    name: "user-center-code-record"
  }, {
    path: "/user-center/collect-record",
    component: _638dfb59,
    name: "user-center-collect-record"
  }, {
    path: "/user-center/coupon-record",
    component: _204160c9,
    name: "user-center-coupon-record"
  }, {
    path: "/user-center/download-record",
    component: _7c2139b2,
    name: "user-center-download-record"
  }, {
    path: "/user-center/leaflet-record",
    component: _3718dee6,
    name: "user-center-leaflet-record"
  }, {
    path: "/user-center/user-info",
    component: _7f673761,
    name: "user-center-user-info"
  }, {
    path: "/user-center/vip-record",
    component: _7dcbfdc6,
    name: "user-center-vip-record"
  }, {
    path: "/act/618/2",
    component: _6e5c86ae,
    name: "act-618-2"
  }, {
    path: "/act/618/3",
    component: _6e4057ac,
    name: "act-618-3"
  }, {
    path: "/act/618/actBar",
    component: _58ffb7bd,
    name: "act-618-actBar"
  }, {
    path: "/act/618/actRule",
    component: _7e73b436,
    name: "act-618-actRule"
  }, {
    path: "/act/618/actWait",
    component: _bc378844,
    name: "act-618-actWait"
  }, {
    path: "/act/618/module",
    component: _2f34576a,
    name: "act-618-module"
  }, {
    path: "/act/hb-capture/module",
    component: _80dce184,
    name: "act-hb-capture-module"
  }, {
    path: "/company/team-manage/invite",
    component: _02eb045f,
    name: "company-team-manage-invite"
  }, {
    path: "/company/team-manage/join",
    component: _0f891d00,
    name: "company-team-manage-join"
  }, {
    path: "/company/team-manage/role",
    component: _3be48ecc,
    name: "company-team-manage-role"
  }, {
    path: "/creator/sign/sign-progress",
    component: _3eb7a3b8,
    name: "creator-sign-sign-progress"
  }, {
    path: "/creator/sign/sign-renew",
    component: _567e63dc,
    name: "creator-sign-sign-renew"
  }, {
    path: "/creator/sign/sign-write",
    component: _10a22264,
    name: "creator-sign-sign-write"
  }, {
    path: "/creator/user-center/certificate",
    component: _6cbb3922,
    name: "creator-user-center-certificate"
  }, {
    path: "/creator/works-manage/WorkDrawer",
    component: _eb174638,
    name: "creator-works-manage-WorkDrawer"
  }, {
    path: "/fans/common/mixin",
    component: _5ea9499d,
    name: "fans-common-mixin"
  }, {
    path: "/fans/demand-pool/mixin",
    component: _777d4c24,
    name: "fans-demand-pool-mixin"
  }, {
    path: "/introduce/multiple/module",
    component: _448937a4,
    name: "introduce-multiple-module"
  }, {
    path: "/pay/edu/module",
    component: _978326ce,
    name: "pay-edu-module"
  }, {
    path: "/pay/enterprise-recharge/coupon-pulldown",
    component: _2f92f631,
    name: "pay-enterprise-recharge-coupon-pulldown"
  }, {
    path: "/pay/enterprise-recharge/module",
    component: _5ce55236,
    name: "pay-enterprise-recharge-module"
  }, {
    path: "/pay/enterprise-recharge/transfer",
    component: _1e2e610a,
    name: "pay-enterprise-recharge-transfer"
  }, {
    path: "/pay/leaflet/module",
    component: _1c422aa0,
    name: "pay-leaflet-module"
  }, {
    path: "/pay/personal-recharge/coupon-pulldown",
    component: _b29a5d1c,
    name: "pay-personal-recharge-coupon-pulldown"
  }, {
    path: "/pay/personal-recharge/module",
    component: _1ee11ab7,
    name: "pay-personal-recharge-module"
  }, {
    path: "/protocol/common/protocol-extend",
    component: _6c808af2,
    name: "protocol-common-protocol-extend"
  }, {
    path: "/protocol/components/about-company",
    component: _019a2cfe,
    name: "protocol-components-about-company"
  }, {
    path: "/protocol/components/common-problem",
    component: _22c06f29,
    name: "protocol-components-common-problem"
  }, {
    path: "/protocol/components/login",
    component: _286e2ea6,
    name: "protocol-components-login"
  }, {
    path: "/protocol/components/vip",
    component: _48a82261,
    name: "protocol-components-vip"
  }, {
    path: "/survey/user/components",
    component: _fcf51046,
    name: "survey-user-components"
  }, {
    path: "/survey/user/module",
    component: _95a2202c,
    name: "survey-user-module"
  }, {
    path: "/tupian/common/mixin",
    component: _7eba5544,
    name: "tupian-common-mixin"
  }, {
    path: "/tupian/common/model",
    component: _cc74f4c8,
    name: "tupian-common-model"
  }, {
    path: "/user-center/collect-record/module",
    component: _373d44fa,
    name: "user-center-collect-record-module"
  }, {
    path: "/user-center/coupon-record/module",
    component: _181eab2c,
    name: "user-center-coupon-record-module"
  }, {
    path: "/user-center/download-record/module",
    component: _d4c2a670,
    name: "user-center-download-record-module"
  }, {
    path: "/user-center/vip-record/module",
    component: _517b4767,
    name: "user-center-vip-record-module"
  }, {
    path: "/act/618/actBar/bar",
    component: _1973bb3e,
    name: "act-618-actBar-bar"
  }, {
    path: "/introduce/multiple/components/authorized-scope",
    component: _27a5de76,
    name: "introduce-multiple-components-authorized-scope"
  }, {
    path: "/introduce/multiple/components/Question",
    component: _619a40da,
    name: "introduce-multiple-components-Question"
  }, {
    path: "/introduce/multiple/components/vip-card-group",
    component: _76a7a706,
    name: "introduce-multiple-components-vip-card-group"
  }, {
    path: "/survey/user/components/age",
    component: _032b75c2,
    name: "survey-user-components-age"
  }, {
    path: "/survey/user/components/industry",
    component: _dbf8ed24,
    name: "survey-user-components-industry"
  }, {
    path: "/survey/user/components/job",
    component: _30024e46,
    name: "survey-user-components-job"
  }, {
    path: "/user-center/download-record/components/CertificationModal",
    component: _23145821,
    name: "user-center-download-record-components-CertificationModal"
  }, {
    path: "/enterprise/case/detail/:id?",
    component: _9fb462d2,
    name: "enterprise-case-detail-id"
  }, {
    path: "/company/pay/:id?",
    component: _0eb937a7,
    name: "company-pay-id"
  }, {
    path: "/fans/demand-detail/:id?",
    component: _619f9139,
    name: "fans-demand-detail-id"
  }, {
    path: "/fans/demand-detail/:mixin?",
    component: _c4e10148,
    name: "fans-demand-detail-mixin"
  }, {
    path: "/fans/demand-pool/:id?",
    component: _8a138978,
    name: "fans-demand-pool-id"
  }, {
    path: "/fans/member-demand-pool-detail/:id?",
    component: _8bb8714e,
    name: "fans-member-demand-pool-detail-id"
  }, {
    path: "/pay/enterprise-recharge/:id?",
    component: _56672c3d,
    name: "pay-enterprise-recharge-id"
  }, {
    path: "/pay/leaflet/:id?",
    component: _11131727,
    name: "pay-leaflet-id"
  }, {
    path: "/pay/package/:id?",
    component: _7176d720,
    name: "pay-package-id"
  }, {
    path: "/pay/personal-recharge/:id?",
    component: _6a0d20fe,
    name: "pay-personal-recharge-id"
  }, {
    path: "/pay/upgrade/:id?",
    component: _50d07774,
    name: "pay-upgrade-id"
  }, {
    path: "/collection/:id?",
    component: _3da74dd3,
    name: "collection-id"
  }, {
    path: "/detail/:id?",
    component: _640a9d86,
    name: "detail-id"
  }, {
    path: "/invite-h5/:id",
    component: _13508222,
    name: "invite-h5-id"
  }, {
    path: "/invite/:id",
    component: _57cc7c3e,
    name: "invite-id"
  }, {
    path: "/pay-company-plus/:id?",
    component: _72cb3ea4,
    name: "pay-company-plus-id"
  }, {
    path: "/pay/:id?",
    component: _126da039,
    name: "pay-id"
  }, {
    path: "/pic-package/:id?",
    component: _3a16d594,
    name: "pic-package-id"
  }, {
    path: "/tags/:id?",
    component: _0f3a45ce,
    name: "tags-id"
  }, {
    path: "/topic/:id?",
    component: _d9fcab80,
    name: "topic-id"
  }, {
    path: "/tupian/:id?",
    component: _78b3f51c,
    name: "tupian-id"
  }, {
    path: "/venue/:id?",
    component: _d56ff100,
    name: "venue-id"
  }, {
    path: "/",
    component: _63e0c138,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
