/* 前端 vip定义，部分id和后端定义不一致，使用函数转换即可 */
import {COMPANY_RESALE, COMPANY_SENIOR, COMPANY_UPGRADE} from "@/constant/VIP_COMPANY";

export const VIP_MANAGE = {

  /* 个人vip */
  PERSON: {
    INFO: {
      LABEL: '个人VIP',
      ID: 1,
      CLASS_NAME: 'person',

    },
    FOREVER: {
      ID: 19, // 后端9
      LABEL: '个人VIP终身',
      PARENT_ID: 1, // 个人vip
      PRICE: 299,
      REAL_ID: 9,
      REAL_DURATION_TYPE: 6,  // 时长类型
      REAL_UPGRADE: 1,
      DOWN_NUMBER: "海量下载"
    },

    YEAR_FIVE: {
      ID: 11,
      LABEL: '个人VIP五年',
      PARENT_ID: 1, // 个人vip
      PRICE: 389,
      REAL_ID: 11,
      REAL_DURATION_TYPE: 6,  // 时长类型
      REAL_UPGRADE: 1,
      DOWN_NUMBER: "海量下载"
    },

    YEAR: {
      ID: 1,
      LABEL: '个人VIP包年',
      PARENT_ID: 1,
      PRICE: 89,
      REAL_ID: 1,
      REAL_DURATION_TYPE: 5,  // 时长类型
      REAL_UPGRADE: 1,
      DOWN_NUMBER: "海量下载"
    },
    QUARTER: {
      ID: 5,
      LABEL: '个人VIP包季',
      PARENT_ID: 1,
      PRICE: 69,
      REAL_ID: 5,
      REAL_DURATION_TYPE: 4,  // 时长类型
      REAL_UPGRADE: 1,
      DOWN_NUMBER: "50张/天"
    },
    MONTH: {
      ID: 7,
      LABEL: '个人VIP包月',
      PARENT_ID: 1,
      PRICE: 39,
      REAL_ID: 7,
      REAL_DURATION_TYPE: 3,  // 时长类型
      REAL_UPGRADE: 1,
      DOWN_NUMBER: "10张/天"
    },
    EXPERIENCE: {
      ID: 8,
      LABEL: '个人VIP包天',
      PARENT_ID: 1,
      PRICE: 0,
      REAL_ID: 8,
      REAL_DURATION_TYPE: 1,  // 时长类型
      REAL_UPGRADE: 1
    },

  },

  /* 商用vip */
  BUSINESS: {
    INFO: {
      LABEL: '商用VIP',
      ID: 2,
      CLASS_NAME: 'business',

    },
    YEAR: {
      ID: 2,
      LABEL: '商用VIP包年',
      PARENT_ID: 2,
      PRICE: 199,
      REAL_ID: 2,
      REAL_DURATION_TYPE: 5,  // 时长类型
      REAL_UPGRADE: 2
    },
    QUARTER: {
      ID: 6,
      LABEL: '商用VIP包季',
      PARENT_ID: 2,
      PRICE: 69,
      REAL_ID: 6,
      REAL_DURATION_TYPE: 4,  // 时长类型
      REAL_UPGRADE: 1
    },
    MONTH: {
      ID: 4,
      LABEL: '商用VIP包月',
      PARENT_ID: 2,
      PRICE: 49,
      REAL_ID: 4,
      REAL_DURATION_TYPE: 3,  // 时长类型
      REAL_UPGRADE: 1
    },
    EXPERIENCE: {
      ID: 17,
      LABEL: '商用VIP包天',
      PARENT_ID: 2,
      PRICE: 0,
      REAL_ID: 10,
      REAL_DURATION_TYPE: 1,  // 时长类型
      REAL_UPGRADE: 1
    }
  },

  COMPANY: {
    INFO: {
      LABEL: '企业VIP',
      ID: 3,
      CLASS_NAME: 'company',
    },

    ONLINE: {
      ID: 9,
      LABEL: '企业线上版',
      PARENT_ID: 3,
      PRICE: 199,
      ACCOUNT_NUMBER: 1,
      REAL_ID: 1,
      REAL_UPGRADE: 2,
      REAL_DURATION_TYPE: 3,  // 时长类型
    },
    ONLINE_HALF: {
      ID: 33,
      LABEL: '企业VIP线上用途_半年',
      PARENT_ID: 3,
      PRICE: 129,
      REAL_ID: 3,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 3,  // 时长类型
    },

    /*All_PURPOSE: {
      ID: 10,
      LABEL: '企业标准版',
      PARENT_ID: 3,
      PRICE: 399,
      ACCOUNT_NUMBER: 1,
      REAL_ID: 2,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 5,  // 时长类型
    },*/
    BASIC_100: {
      ID: 1001,
      // LABEL: '基础版',
      LABEL: '全站通套餐A',
      PARENT_ID: 3,
      PRICE: 69,
      ACCOUNT_NUMBER: 1,
      REAL_ID: 17,
      REAL_UPGRADE: 14,
      REAL_DURATION_TYPE: 5,  // 时长类型
    },

    All_PURPOSE: {
      ID: 10,
      // LABEL: '企业标准版',
      LABEL: '全站通套餐B',
      PARENT_ID: 3,
      PRICE: 300,
      ACCOUNT_NUMBER: 1,
      REAL_ID: 2,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 5,  // 时长类型
    },
    /* 新标准版 */
    All_PURPOSE_NEW: {
      ID: 38,
      LABEL: '企业标准版',
      PARENT_ID: 3,
      PRICE: 399,
      ACCOUNT_NUMBER: 1,
      REAL_ID: 8,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 5,  // 时长类型
    },
    All_PURPOSE_HALF: {
      ID: 34,
      LABEL: '企业VIP全用途_半年',
      PARENT_ID: 3,
      PRICE: 229,
      REAL_ID: 4,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 5,  // 时长类型

    },

    UPGRADE: {
      ID: 35,
      // LABEL: '企业升级版',
      LABEL: '全站通套餐C',
      PARENT_ID: 3,
      PRICE: 990,
      // PRICE: 790,
      ACCOUNT_NUMBER: 4,
      REAL_ID: 5,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 6,  // 时长类型

    },
    SENIOR: {
      ID: 36,
      LABEL: '企业高级版',
      PARENT_ID: 3,
      PRICE: 2600,
      ACCOUNT_NUMBER: 10,
      REAL_ID: 6,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 7,  // 时长类型

    },
    RESALE: {
      ID: 37,
      LABEL: '企业转售版',
      PARENT_ID: 3,
      PRICE: 5600,
      ACCOUNT_NUMBER: 20,
      REAL_ID: 7,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 8,  // 时长类型
    },

    /* 24.5.23新增vip */
    BASIC:{
      ID: 300,
      // LABEL: '普通版',
      LABEL: '全站通套餐C',
      PARENT_ID: 3,
      PRICE: 990,
      ACCOUNT_NUMBER: 2,
      REAL_ID: 14,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 6,  // 时长类型
    },

    MAJOR:{
      ID: 301,
      // LABEL: '专业版',
      LABEL: '全站通套餐D',
      PARENT_ID: 3,
      PRICE: 1990,
      ACCOUNT_NUMBER: 5,
      REAL_ID: 15,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 7,  // 时长类型
    },
    RELEASE:{
      ID: 302,
      // LABEL: '发行版',
      LABEL: '全站通套餐E',
      PARENT_ID: 3,
      PRICE: 4900,
      ACCOUNT_NUMBER: 8,
      REAL_ID: 16,
      REAL_UPGRADE: 1,
      REAL_DURATION_TYPE: 8,  // 时长类型
    }
  },

}

/* vip数组，用于检索 */
VIP_MANAGE.LIST = [
  VIP_MANAGE.PERSON.FOREVER,
  VIP_MANAGE.PERSON.YEAR,
  VIP_MANAGE.PERSON.YEAR_FIVE,
  VIP_MANAGE.PERSON.QUARTER,
  VIP_MANAGE.PERSON.MONTH,
  VIP_MANAGE.PERSON.EXPERIENCE,
  VIP_MANAGE.BUSINESS.YEAR,
  VIP_MANAGE.BUSINESS.QUARTER,
  VIP_MANAGE.BUSINESS.MONTH,
  VIP_MANAGE.BUSINESS.EXPERIENCE,
  VIP_MANAGE.COMPANY.ONLINE,
  VIP_MANAGE.COMPANY.ONLINE_HALF,
  VIP_MANAGE.COMPANY.UPGRADE,
  VIP_MANAGE.COMPANY.SENIOR,
  VIP_MANAGE.COMPANY.RESALE,
  VIP_MANAGE.COMPANY.All_PURPOSE_HALF,
  VIP_MANAGE.COMPANY.All_PURPOSE,
  VIP_MANAGE.COMPANY.All_PURPOSE_NEW,
]

/* vip类型 */
VIP_MANAGE.TYPE = [
  VIP_MANAGE.PERSON.INFO,
  VIP_MANAGE.BUSINESS.INFO,
  VIP_MANAGE.COMPANY.INFO,
]

/* 配置常用 类型和版本数组 */
VIP_MANAGE.VIPS = [
  {
    ...VIP_MANAGE.PERSON.INFO,
    LIST: [
      VIP_MANAGE.PERSON.FOREVER,
      VIP_MANAGE.PERSON.YEAR,
      VIP_MANAGE.PERSON.QUARTER,
    ]
  },
  {
    ...VIP_MANAGE.BUSINESS.INFO,
    LIST: [
      VIP_MANAGE.BUSINESS.YEAR,
      VIP_MANAGE.BUSINESS.QUARTER,
      VIP_MANAGE.BUSINESS.MONTH,
    ]
  },
  {
    ...VIP_MANAGE.COMPANY.INFO,
    LIST: [
      VIP_MANAGE.COMPANY.ONLINE,
      VIP_MANAGE.COMPANY.ONLINE_HALF,
      VIP_MANAGE.COMPANY.All_PURPOSE,
      VIP_MANAGE.COMPANY.All_PURPOSE_HALF,
      VIP_MANAGE.COMPANY.UPGRADE,
      VIP_MANAGE.COMPANY.SENIOR,
      VIP_MANAGE.COMPANY.RESALE,
    ]
  }
]
