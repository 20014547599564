/* 处理套餐列表，更具id显示对应的时长中文等等 */
import vip from "@/pages/protocol/components/vip.vue";

export function disposeVipList(vipMealList) {
  vipMealList.forEach(item => {
    let vipId = parseInt(item.REAL_ID);

    if (vipId === 1 || vipId === 2) {
      item.DURATION = '1年'
    } else if (vipId === 6 || vipId === 5) {
      item.DURATION = '3个月'
    } else if (vipId === 4 || vipId === 7) {
      item.DURATION = '1个月'
    } else if (vipId === 19 || vipId === 9) {
      item.DURATION = '终身'
    } else if (vipId === 11) {
      item.DURATION = '5年'
    }

    if (Array.isArray(item.LIST) && item.LIST.length) {
      disposeVipList(item.LIST)
    }
  })
  return vipMealList;
}

/* 更具vip获取vipname */
export function getVipNameByVip(vip){
  const vipName = {
    1: "企业线上版",
    2: "全站通套餐B",  // (原企业普通版，不影响VIP权益)
    3: "企业线上版半年",
    4: "企业全用途半年",
    5: "企业升级版",
    6: "企业高级版",
    7: "企业转售版",
    8: "企业标准版",
    9: "企业定制版",
    10: "大型企业线上版",
    11: "大型企业线下版",
    12: "医美行业专业版",
    13: "多甲方授权版",
    14: "企业普通版",
    15: "全站通套餐C", //  (原企业专业版，不影响VIP权益)
    16: "全站通套餐D", // (原企业发行版，不影响VIP权益)
    17: "全站通套餐A", // (原企业基础版，不影响VIP权益)
  }

  const vipKeys = Object.keys(vipName)
  if (vipKeys.indexOf(vip) ==  -1) {
    console.log("vip传参错误")
    return "";
  }

  return vipName[vip];

}

/* 前端和后端会员id不一致，获取真实的后端会员id */
export function getVipPayId(id, module) {

}
