<template>
  <div class="ActGuoQingBar" @click="navToGuoQingAct"></div>
</template>

<script>
import { fnBasic } from "@/fn/_fn";
import { ACTIVE_GUOQING } from "@/constant/_constant/CON_BASIC";

export default {
  name: "ActGuoQingBar",
  methods: {
    navToGuoQingAct() {
      fnBasic.navigationTo(ACTIVE_GUOQING)
    }
  }
}
</script>

<style lang="scss" scoped>
.ActGuoQingBar {
  width: 100%;
  display: block;
  transition: all 0.5s ease;
  @include BgImage(100%, 60px, 'act/guoqing/ActGuoQingBar3.jpg', 1);
  vertical-align: middle;
  background-size: 1920px 60px;
  cursor: pointer;

  img {
    width: 100%;
    display: block;
    transition: all 0.8s ease;
  }
}
</style>
