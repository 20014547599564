<!--/**
* @Author: wulizhou
* @Description: 全局组件卡槽
* @Date:
**/-->
<template>
  <div class="PublicSlot">
    <no-ssr>
      <!-- 免费下载红包弹框  -->
      <PicFreeDownCouponModal/>

      <!-- 独立绑定手机弹框 -->
      <UserBindIphoneAloneModal/>

      <!-- 组合型绑定手机弹框 -->
      <UserBindIphoneModal/>
      <UserCertificationModal/>

      <CountdownBubble/>

      <!--  全电发票弹框  -->
      <InvoiceRemindModal/>

      <!-- 企业vip过期或张数使用完毕拦截弹框 -->
      <CompanyVipExpiredModal/>

      <!--  灰产账号封禁 -->
      <BlackModal />

      <UpdateProtocolModal_20240426 />


      <!--  企业认证填写弹框 -->
      <CompanyHomeCertificate />
      <mainRiskWarningModal />
      <mainInReviewModal />

    </no-ssr>
  </div>
</template>

<script>
import PicFreeDownCouponModal from "~/components/_Privately/Pic/PicFreeDownCouponModal";
import UserBindIphoneModal from "@/components/_Privately/User/UserBindIphoneModal/UserBindIphoneModal";
import UserCertificationModal from "@/components/_Privately/User/UserCertificationModal/UserCertificationModal";
import UserBindIphoneAloneModal from '@/components/_Privately/User/UserBindIphoneAloneModal'
import CountdownBubble from "@/components/_components/fans/club/CountdownBubble"
import SignByDayModal from "@/components/_components/active/SignByDayModal";
import InvoiceRemindModal from "@/components/_components/user/InvoiceRemindModal";
import CompanyVipExpiredModal from '@/components/_components/user/CompanyVipExpiredModal'
import BlackModal from "@/components/_Privately/Pic/BlackModal"
import CompanyHomeCertificate from "@/components/company/common/mainAuthenticaltionWriteModal/certificate-dialog.vue";
import mainRiskWarningModal from "@/components/company/common/mainRiskWarningModal/mainRiskWarningModal.vue";
import mainInReviewModal from "@/components/company/common/mainInReviewModal/index.vue";
const UpdateProtocolModal_20240426 = () => import("@/components/_Privately/Act/UpdateProtocol/UpdateProtocolModal_20240426.vue");

/* 引入API */

export default {
  name: "PublicSlot"

  , components: {
    PicFreeDownCouponModal,
    UserBindIphoneModal,
    UserCertificationModal,
    UserBindIphoneAloneModal,
    SignByDayModal,
    CountdownBubble,
    InvoiceRemindModal,
    CompanyVipExpiredModal,
    BlackModal,
    UpdateProtocolModal_20240426,
    CompanyHomeCertificate,
    mainRiskWarningModal,
    mainInReviewModal
  }

  , props: {}

  , watch: {}

  , computed: {}

  , data() {
    return {}
  }

  , methods: {}

}
</script>

<style lang="scss" scoped>

</style>
