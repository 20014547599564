import {getPayCode, getPayResult, payCenterTracking, payLeaflet, payUpgrade} from "@/api/pay";
import {VIP_LIST} from "@/constant/VIP";
import {fnLogin} from "@/fn/index";
import {setTagCookie, tagStat} from "@/api/stat";
import {VIP_MANAGE} from "@/constant/VIP_MANAGE";
import {PAY} from "@/constant/PAY";

class FnPay {
  constructor() {

  }


  /* 充值漏斗 */
  payTracking(stat){
    tagStat({stat}).then(res => {
      setTagCookie({stat})
    })
  }

  /* 支付中心访问日志统计 1 个人VIP中心,2 企业VIP中心 */
  payCenterTracking(position){
    payCenterTracking({
      'position': position
    }).then(res =>{

    })
  }

  /**
   * 根据套餐id获取支付码 ( 微信/支付宝 )
   * @param { Number } id 套餐id vip会员传套餐id 图片包传master_id就是主图的uniq_id
   * @param { Number } module 支付类型 1.购买会员 2.购买图片包 3.企业会员
   * @returns {Promise<unknown>} {  paymemt: 支付信息, ali_pay 支付宝信息 , wechat_pay 微信支付信息 }
   */
  async getPayCode(id, module = 1,coupon_id) {

    return new Promise((resolve, reject) => {
      let payment = {};
      let params = module === 2 ? {master_id: id, module: 2} : {vip: id};

      if (module === 1 || module === 3) {
        params = {vip: id, module}
        if (coupon_id){
          params = {vip: id, module,coupon_id}
        }
      } else {
        params = {master_id: id, module}
      }

      getPayCode(params).then(res => {
        let {ali_pay, wechat_pay, status, amount, discount} = res.data;
        if (status === 1) {
          (wechat_pay.pay_name === 'wft') && (payment.isShowWft = true);
          payment.zfbUrl = ali_pay.code_url;
          payment.wxUrl = wechat_pay.code_img_url;
          resolve({
            payment,
            ali_pay,
            wechat_pay,
            amount,
            discount
          })
        }else{
          reject(res.data);
        }
      }).catch(err => {
        reject(err);
      })
    })
  }

  async getPayUpgradeCode(params) {

    return new Promise((resolve, reject) => {
      let payment = {};
      payUpgrade(params).then(res => {
        let {ali_pay, wechat_pay,jd_pay, status, amount} = res.data;
        if (status === 1) {
          (wechat_pay.pay_name === 'wft') && (payment.isShowWft = true);
          payment.zfbUrl = ali_pay.code_url;
          payment.wxUrl = wechat_pay.code_img_url;
          payment.jdUrl = jd_pay.code_img_url;
          payment.amount = amount;
          resolve({
            payment,
            ali_pay,
            wechat_pay,
            jd_pay,
          })
        } else if (status == -3 || status == -2) {
          window.location.href = '/'
        }
      }).catch(err => {
        reject(err);
      })
    })
  }


  async getPayCOdeByLeaflet(params){

    return new Promise((resolve, reject) => {
      let payment = {};
      payLeaflet(params).then(res => {
        let {ali_pay, wechat_pay, jd_pay, status, amount, discount_type,discount_amount} = res.data;
        if (status === 1) {
          (wechat_pay.pay_name === 'wft') && (payment.isShowWft = true);
          payment.zfbUrl = ali_pay.code_url;
          payment.wxUrl = wechat_pay.code_img_url;
          payment.jdUrl = jd_pay.code_img_url;
          payment.amount = amount;
          payment.discount_type = discount_type;

          if (discount_type == 2){
            payment.discount_amount = discount_amount / 100;
          }else{
            payment.discount_amount = discount_amount
          }
          resolve({
            payment,
            ali_pay,
            wechat_pay,
            jd_pay,
          })
        } else if (status == -3 || status == -2) {
          window.location.href = '/'
        }
      }).catch(err => {
        reject(err);
      })
    })

  }


  /**
   * 查询订单支付结果
   * @param { String | Number } order_no  订单号
   * @returns {Promise<unknown>} 返回 { status: 支付状态, msg: 提示信息 }
   */
  getPayResult(order_no) {
    // clearInterval(this.timer);
    let {$store} = $nuxt;
    return new Promise(((resolve, reject) => {
      getPayResult({order_no: order_no})
        .then(res => {
          let {result, status} = res.data;
          if (status == 1) {
            // 更新用户信息
            $store.dispatch('user/getUserInfo');
            resolve({
              status: 1,
              msg: '支付成功'
            })
          }
        })
        .catch(err => {
          reject({
            status: 0,
            msg: '支付失败'
          })

        })
    }))
  }

  /**
   * 根据id查询套餐信息
   * @param { Number } id 套餐id
   * @returns { Object } 根据id返回套餐信息
   */
  getCurrentVipInfo(id) {
    return ;
    if (id === 9){
      id = 19
    }
    return VIP_LIST.find(vo => vo.ID == id)
  }


  /* 升级路由拦截 */
  interceptPayUpgrad(app,store, route,next) {
    if (route.path === '/upgrade') {
      if (fnLogin.checkLogin() && store.state.user.uniq_id !== route.query.uid) {
        window.location.href = '/'
      }

      payUpgrade(route.query).then(res =>{
        if (res.data.status == -3 || res.data.status == -2){
          window.location.href = '/'
        }
      })

    }
  }


  /* 通过前端会员id查找套餐id */
  findVipByFrontId(id){
    let vips = [];
    PAY.VIPS.forEach(item =>{
      vips = vips.concat(item.LIST)
    })

    return vips.find(vo => vo.ID == id)
  }


  /* 根据会员id 查找会员类型 */
  getVipTypeByFrontVipId(id){
    return PAY.VIPS.find(vo => vo.ID === id)
  }



  /* 根据会员类型查找套餐数组 */
  findVipListByVipType(type){
    let arr = [];
    VIP_MANAGE.LIST.forEach(vo =>{
      if (vo.PARENT_ID === parseInt(type)){
        arr.push(vo)
      }
    })
    return arr;
  }

  frontVipIdConvertBackend(vo){
    let tempId;
    if (vo.PARENT_ID === 3) {

      if (vo.ID === 9) {
        tempId = 1
      } else if (vo.ID === 10) {
        tempId = 2
      } else if (vo.ID === 33) {
        tempId = 3
      } else if (vo.ID === 34) {
        tempId = 4
      }else if (vo.ID === 35) {
        tempId = 5
      }else if (vo.ID === 36) {
        tempId = 6
      }else if (vo.ID === 37) {
        tempId = 7
      }else if (vo.ID === 38) {
        tempId = 8
      }else if (vo.ID === 300) {
        tempId = 14
      }else if (vo.ID === 301) {
        tempId = 15
      }else if (vo.ID === 302) {
        tempId = 16
      }else if (vo.ID === 1001) {
        tempId = 17
      }

    }
    else if (vo.PARENT_ID === 1){
      if (vo.ID === 19) {
        tempId = 9
      }else{
        tempId = vo.ID
      }
    }else {
      tempId = vo.ID
    }

    return tempId


  }




}

export default new FnPay();
