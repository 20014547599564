export const USER_VIP_TYPE = {
  NO: '0',      // 非会员
  ON: '1',       // 会员
  OUT: '-1',      // 过期会员
}

export const BLANK_LIST = [
  "P01896423",
  "P21376480",
  "P32549810",
  "P59467032",
  "P80342691",
  "P10528794",
  "P32047685",
  "P90134528",
  "P61750493",
  "P07984512",
  "P98354726",
  "P34721850",
  "P86071529",
  "P81203796",
  "P96235708",
  "P43598206",
  "P62391847",
  "P87413620",
  "P23508196",
  "P69358702",
]
