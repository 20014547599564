/*
  * Filename: 首页配置项
  * Description: 首页配置项，其他请写在对应模块，不允许穿插写入
  * Author: wulizhou
  * Time: 2022.01.20
  * Version: 1.0
*/
import {
  HOME,
  MATERIAL_LIST,
  MEMBER_LICENSE_VIP,
  MARKETING_CALENDAR,
  TIMELINE_UPDATE, PIC_PACKAGE,
} from '@/constant/PATH.js'
import {PAGE_PATH} from "@/constant/PAGE_PATH";

/* 首页顶部栏菜单 */
export const topbarMenuList = [
  {
    id: 0,
    name: "全部热点",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.HOT
  },
  {
    id: 4,
    name: "3D",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.THREE_D
  },
  {
    id: 2,
    name: "节日节气",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.FESTIVAL
  },
  {
    id: 3,
    name: "24节气",
    path: MATERIAL_LIST
  },
  {
    id: 98,
    name: "图片包",
    path: PIC_PACKAGE
  },
  /* 营销日历 时间轴 图片包 */
  {
    id: 100,
    name: "营销日历",
    path: MARKETING_CALENDAR
  },
  {
    id: 101,
    name: "专题更新",
    path: PAGE_PATH.BASE_URL + PAGE_PATH.TOPIC.DEFAULT
  },
  {
    id: 99,
    name: "时间轴更新",
    path: TIMELINE_UPDATE
  },
  {
    id: 95,
    name: "邀请有礼",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.INVITE.DEFAULT
  },
  {
    id: 43,
    name: "电商",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.BUSINESS
  },
  {
    id: 102,
    name: "免费专区",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.FREE
  },

  {
    id: 103,
    name: "设计风格",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.STYLE
  },

  {
    id: 1,
    name: "热点",
    path: PAGE_PATH.BASE_URL+PAGE_PATH.NAV.HOT_DIAN
  },

]


/* 首页导航配置  */
export const menuNavList = [
  {
    name: "全部",
    id: 0,
    className: "all",
    url: "",
    bgColor: "",
    hoverBgColor: "#17A1FF"
  },
  {
    name: "海报",
    id: 1,
    url: "",
    className: "poster",
    bgColor: "",
    hoverBgColor: "#FA4633"
  },
  {
    name: "插画",
    id: 2,
    className: "illustration",
    url: "",
    bgColor: "",
    hoverBgColor: "#FF9400"
  },
  {
    name: "电商",
    id: 3,
    className: "business",
    url: "",
    bgColor: "",
    hoverBgColor: "#EF2B79"
  },
  {
    name: "画册",
    id: 4,
    className: "painting",
    url: "",
    bgColor: "",
    hoverBgColor: "#FFC001"
  },
  {
    name: "卡通",
    id: 5,
    className: "cartoon",
    url: "",
    bgColor: "",
    hoverBgColor: "#7265E2"
  },
  {
    name: "简约",
    id: 6,
    className: "simple",
    url: "",
    bgColor: "",
    hoverBgColor: "#00CFDD"
  },
  {
    name: "国潮",
    id: 7,
    className: "g-trend",
    url: "",
    bgColor: "",
    hoverBgColor: "#1ABC9C"
  },
  {
    name: "手绘",
    id: 8,
    className: "freehand",
    url: "",
    bgColor: "",
    hoverBgColor: "#EF2B79"
  },
  {
    name: "3D",
    id: 9,
    className: "model",
    url: "",
    bgColor: "",
    hoverBgColor: "#00CFDD"

  },
  {
    name: "中国风",
    id: 10,
    className: "g-style",
    url: "",
    bgColor: "",
    hoverBgColor: "#FFC001"
  },

]

/* 日历菜单 */
export const calendarMenuList = [
  "3D渲染",
  "万圣节",
  "装饰图",
  "圣诞节",
  "中国风",
  "神舟13号",

]

/* 素材列表 */
export const materialList = {
  "今天": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: true,
    },
    {
      tag: "合集1",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test1.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: true,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test1.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: true,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test1.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test1.png",
      isCollect: true,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test1.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
  "周四": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test1.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: true,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: true,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
  "周三": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
  "周二": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
  "周一": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
  "周日": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
  "周六": [
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
    {
      tag: "合集",
      url: "",
      imgUrl: "//static.588ku.com/chaotu/test/material-img-test.png",
      isCollect: false,
    },
  ],
}

/* 工作效率 */
export const workEfficiencyData = {
  headline: "全方位提高您的工作效率",
  content:[
    {
      iconClass:"icon-editor",
      title:"编辑矢量",
      details:"我们所有的图都包含原始适量设计文件，并且完全可编辑，您可以轻松自定义它们以适合您的品牌需求。"
    },
    {
      iconClass:"icon-copyright",
      title:"版权授权",
      details:"一旦您购买「版本授权」服务，您就可以永久获得授权，没有任何限制，同时也能享受在服务期限内的保障。"
    },
    {
      iconClass:"icon-dingzhi2",
      title:"定制设计",
      details:"我们提供定制图，可以遵循我们的风格，也可以创建一个全新的以适合您品牌的风格，这样您将始终获得所需的东西。"
    },
    {
      iconClass:"icon-copyright",
      title:"内容更新",
      details:"我们的图库与日俱增，不断添加新的样式和场景，使其成为您项目的最终资源来源。"
    }
  ]

}

/* 可用应用程序 */
export const applicationData = {
  headline:"在所有应用程序都能流畅工作",
  subhead: `我们的图片主要来源于最优秀的设计师，这些设计师有着创意灵感，他们的设计蕴含着潮流概念。<br>
            这些也是企业、UI设计师和分析师使用和寻找的。`,
  iconList: [
    {
      iconClass: "icon-sketch",
      title:"Sketch App"
    },
    {
      iconClass: "icon-ae",
      title:"Sketch After Effect"
    },
    {
      iconClass: "icon-ai",
      title:"Sketch Illustrator"
    },
    {
      iconClass: "icon-ps",
      title:"Sketch Photoshop"
    },
  ],
  bmpHeadline :"海量文件格式，兼容主流设计软件",
  bpmList:[
    ".ae",
    ".c4d",
    ".eps",
    ".psd",
    ".png",
    ".ai",
    ".sketch",
  ]

}


